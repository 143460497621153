#form-checkout {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

.container {
    height: 50px;
    display: inline-block;
    border: 1px solid rgb(118, 118, 118);
    padding: 1px 2px;
    margin: 10px;
    background: #FCF6EA;
    border-radius: 4px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 18px;
    color: #737373
}

.warning-fields {
    color: red;
    margin: 10px;
}

.disabled {
    pointer-events:none; /* No cursor */
    background-color: #eee; /* Gray background */
}